import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import {
	setFlex,
	setRem,
	setColor,
	setLetterSpacing,
	media,
} from "../styles/styles";
import Layout from "../components/layout";
import SEO from "../components/seo";

const About = ({ data }) => {
	return (
		<Layout>
			<SEO title="About" />
			<AboutWrapper>
				<section>
					<div className="title-container">
						<h1>About</h1>
					</div>
					<div className="info">
						Hello, I'm Alex, a Software Developer based in New York City. With a
						background in Library Science and music, I bring logic and
						creativity to my approach to coding.
					</div>
				</section>
			</AboutWrapper>
		</Layout>
	);
};

const AboutWrapper = styled.main`
  ${setFlex()};
  margin-bottom: ${setRem(64)};
  section {
    width: 90%;
    max-width: ${setRem(600)};
    padding: ${setRem(48)};
    ${setFlex()};
    border: 1px solid ${setColor.darkGrey};
    border-radius: 2px;
  }
  .title-container {
    width: ${setRem(150)};
    height: ${setRem(150)};
    padding: ${setRem()};
    ${setFlex({ x: "flex-start" })};
  }
  img {
    border-radius: 50%;
  }
  div {
    margin: 0 ${setRem()};
  }
  .info {
    color: ${setColor.darkGrey};
    ${setLetterSpacing(1.2)};
    line-height: ${setRem(20)};
    flex: 3;
    font-weight: 400;
  }

  ${media.phone`
    section {
      flex-direction: column;
      align-items: flex-start;
      padding: ${setRem(12)};
      padding-bottom: ${setRem(48)};
    }
  `}
`;

export const query = graphql`
  {
    file(relativePath: { eq: "Alex.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default About;
